import { Dropdown } from 'bootstrap';
import Cookies from 'js-cookie';
import Chart from 'chart.js/auto';
import { CookieConsent } from './cookieConsent';

import 'normalize.css';
import './../css/style.scss';

const app = (function() {
    const menuIconToggle = document.getElementById('menu-button');
    const mainHeaderElm = document.getElementById('main-header');

    // Menu button
    if (menuIconToggle) {
        menuIconToggle.addEventListener('click', menuToggleClick);
    }

    function menuToggleClick(evt) {
        evt.stopPropagation();
        if (mainHeaderElm.classList.contains('opened')) {
            mainHeaderElm.classList.remove('opened');
            mainHeaderElm.classList.add('closed');
        } else {
            mainHeaderElm.classList.remove('closed');
            mainHeaderElm.classList.add('opened');
        }
    }

    // Article card click
    // document.querySelectorAll('.js-clickcard').forEach(item => {
    //     // item.style.cursor = 'pointer';
    //     item.addEventListener('click', e => {
    //         let link = item.querySelector('a');
    //         if (link !== e.target) {
    //             link.click();
    //         }
    //     });
    // });

    // const modalAgeVerify = !!document.getElementById('age-verification');

    // function closeCookieWindow() {
    //     if (!modalAgeVerify) return;
    //     modalAgeVerify.style.display = null;
    // }

    // function showCookieWindow() {
    //     if (modalAgeVerify) return;
    //     document.body.classList.add('modal-open');
    // }

    // if (Cookies.get('age-verify') != 'true') {
    //     showCookieWindow();
    // }
    
    // https://www.javascripttutorial.net/javascript-dom/javascript-form/
    // const form = document.getElementById('age-form');
    // const ageVerify = form ? form.elements['age-verify'] : '';
    // const cookieAccept = form ? form.elements['cookie-accept'] : '';

    // function submitForm() {
    //     // Age verify
    //     if (!ageVerify.checked) {
    //         ageVerify.classList.add('error');
    //     } else {
    //         ageVerify.classList.remove('error');
            
    //         Cookies.set('age-verify', 'true');

    //         if (cookieAccept.checked) {
    //             Cookies.set('cookie-accept', 'true');
    //         }

    //         // closeCookieWindow();
    //         refreshPage();
    //     }
    // }

    // function refreshPage() {
    //     location.reload();
    // }

    // function logSubmit(event) {
    //     event.preventDefault();
        
    //     submitForm();
    // }

    // if (form) {
    //     form.addEventListener('submit', logSubmit);
    // }

    /* Cookie Consent Window */
    const cookieConsent = CookieConsent();
    cookieConsent.init({
        'mainElement': document.getElementById('cookie-config'),
        'ageConsentButton': document.getElementById('age-consent-button'),
        'cookieConsentButton': document.getElementById('age-consent-button'),
        'cookieSettingsButton': document.getElementById('cookie-settings-button'),
        'cookieConsentButtonPrimary': document.getElementById('cookie-consent-button-primary'),
        'cookieConsentButtonSecondary': document.getElementById('cookie-consent-button-secondary'),
        'inputAnalyticsCookies': document.getElementById('analytics-cookies'),
        'inputMarketingCookies': document.getElementById('marketing-cookies'),
    });

    /* Alert */
    const alert = document.getElementById('alert');
    const alertUpdated = (alert && alert.dataset.dateUpdated) ? alert.dataset.dateUpdated : 1;
    const alertDisabled = sessionStorage.getItem('alert-disabled');

    if (alertUpdated == alertDisabled) {
        hideAlert();
    }

    if (alert) {
        alert.addEventListener('click', e => {
            e.preventDefault();

            hideAlert();
            sessionStorage.setItem('alert-disabled', alertUpdated);
        });
    }

    function hideAlert() {
        alert.classList.add('hidden');
    }

    /* Flavour wheel */
    const flavourWheelElement = document.getElementsByClassName('flavour-wheel-radar');

    for (let index = 0; index < flavourWheelElement.length; index++) {
        const element = flavourWheelElement[index];
        const flavourData = JSON.parse(element.dataset.flavour);
        
        if (element && 'flavour' in element.dataset === true) {
            drawChart(flavourData, element, element.dataset.background);
        }       
    }
})();

function drawChart(d, element, backgroundColor) {
    if (d) {
        const data = {
            datasets: [{
                label: '',
                data: d,
                fill: true,
                backgroundColor: backgroundColor,
                borderColor: 'transparent',
                color: '#000',
                }],
            labels: ['AROMA', 'ZUUR', 'ZOET ', 'BITTER', 'FRUITIG', 'BODY', 'DROOG', 'NASMAAK']
        };

        const config = {
            type: 'radar',
            data: data,
            options: {
                animation: false,
                elements: {
                    line: {
                        borderWidth: 1,
                    },
                    point: {
                        pointRadius: 0
                    }
                },
                layout: {
                    padding: 0,
                },
                scales: {
                    r: {
                        angleLines: {
                            display: true,
                            color: '#000100'
                        },
                        grid: {
                            display: true,
                            color: '#000100',
                            z: 1
                        },
                        pointLabels: {
                            display: false
                        },
                        ticks: {
                            backdropColor: false,
                            color: 'transparent',
                            showLabelBackdrop: false,
                        },
                        title: {
                            display: false
                        },
                        suggestedMin: 0,
                        suggestedMax: 40,
                    }
                },
                plugins: {
                    labels: {
                        font: {
                            family: 'Rift',
                            lineHeight: 1,
                            size: 13,
                            weight: 600
                        }
                    },
                    legend: {
                        display: false,
                        title: {
                            display: false,
                        }
                    },
                    tooltip: {
                        display: false,
                        enabled: false,
                    }
                }
            },
        };

        const myChart = new Chart(element, config);
    }
}
