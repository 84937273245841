import Cookies from 'js-cookie';

export const CookieConsent = () => {
    let
        ageConsentButton,
        cookieConsentButtonPrimary,
        cookieConsentButtonSecondary,
        cookieSettingsButton,
        inputAnalyticsCookies,
        inputMarketingCookies,
        mainElement,
        currentView;

    const 
        AGE_CONSENT_VIEW = 'age-consent-view',
        COOKIE_CONSENT_VIEW = 'cookie-consent-view',
        COOKIE_SETTINGS_VIEW = 'cookie-settings-view',
        AGE_CONSENT = 'ageConsent',
        ANALYTICAL_COOKIES_ALLOWED = 'analyticalCookiesAllowed',
        ESSENTIAL_COOKIES_ALLOWED = 'essentialCookiesAllowed',
        MARKETING_COOKIES_ALLOWED = 'marketingCookiesAllowed',
        ANALYTICS = 'analytics',
        MARKETING = 'marketing';

    const init = (config) => {
        setConfig(config);
        buildAgeConsentView();
        buildCookieConsentView();
        buildCookieSettingstView();
        // Get correct view
        if (!getAgeConsent()) {
            getView(AGE_CONSENT_VIEW);
        } else if (!getCookie(ESSENTIAL_COOKIES_ALLOWED)) {
            getView(COOKIE_CONSENT_VIEW);
        }

        setAllCookieScripts();
    }

    const hideWindow = () => {
        mainElement.style.display = 'none';
        document.body.classList.remove('modal-open');
    }

    const showWindow = () => {
        mainElement.style.display = 'flex';
        document.body.classList.add('modal-open');
    }

    const setConfig = (config) => {
        if (config['ageConsentButton']) {
            ageConsentButton = config['ageConsentButton'];
        }

        if (config['mainElement']) {
            mainElement = config['mainElement'];
        }

        if (config['cookieSettingsButton']) {
            cookieSettingsButton = config['cookieSettingsButton'];
        }

        if (config['cookieConsentButtonPrimary']) {
            cookieConsentButtonPrimary = config['cookieConsentButtonPrimary'];
        }

        if (config['cookieConsentButtonSecondary']) {
            cookieConsentButtonSecondary = config['cookieConsentButtonSecondary'];
        }

        if (config['inputAnalyticsCookies']) {
            inputAnalyticsCookies = config['inputAnalyticsCookies'];
        }

        if (config['inputMarketingCookies']) {
            inputMarketingCookies = config['inputMarketingCookies'];
        }
    } 

    const setAgeConsent = () => {
        Cookies.set(AGE_CONSENT, 'true');
    }

    const getAgeConsent = () => {
        return Cookies.get(AGE_CONSENT);
    }

    const getCookie = (name) => {
        return Cookies.get(name);
    }

    const setCookie = (name) => {
        Cookies.set(name, 'true');
    }

    const buildAgeConsentView = () => {
        if (ageConsentButton) {
            ageConsentButton.addEventListener('click', ageConsentButtonHandler);
        }
    }

    const buildCookieConsentView = () => {
        if (cookieConsentButtonPrimary) {
            cookieConsentButtonPrimary.addEventListener('click', cookieConsentButtonHandler);
        }

        if (cookieSettingsButton) {
            cookieSettingsButton.addEventListener('click', cookieSettingsButtonHandler);
        }
    }

    const buildCookieSettingstView = () => {
        if (cookieConsentButtonSecondary) {
            cookieConsentButtonSecondary.addEventListener('click', cookieConsentButtonSecondaryHandler);
        }
    }

    const ageConsentButtonHandler = () => {
        setAgeConsent();

        // Check if essential cookies exists
        if (!getCookie(ESSENTIAL_COOKIES_ALLOWED)) {
            getView(COOKIE_CONSENT_VIEW);
        } else {
            hideWindow();
        }
    }

    const cookieSettingsButtonHandler = () => {
        getView(COOKIE_SETTINGS_VIEW);
    }

    const cookieConsentButtonHandler = () => {
        // Close
        hideWindow();

        // Save all cookies
        setCookie(ANALYTICAL_COOKIES_ALLOWED);
        setCookie(ESSENTIAL_COOKIES_ALLOWED);
        setCookie(MARKETING_COOKIES_ALLOWED)

        setAllCookieScripts();
    }

    const cookieConsentButtonSecondaryHandler = () => {
        // Close
        hideWindow();

        // Save cookies
        setCookie(ESSENTIAL_COOKIES_ALLOWED);

        if (inputAnalyticsCookies && inputAnalyticsCookies.checked) {
            setCookie(ANALYTICAL_COOKIES_ALLOWED);
        }

        if (inputMarketingCookies && inputMarketingCookies.checked) {
            setCookie(MARKETING_COOKIES_ALLOWED);
        }

        setAllCookieScripts();
    }

    const getView = (viewName) => {
        // console.log('[getView] (' + viewName + ')');
        if (currentView) {
            hideView(currentView);
        }

        switch (viewName) {
            case AGE_CONSENT_VIEW:
            case COOKIE_CONSENT_VIEW:
            case COOKIE_SETTINGS_VIEW:
                showView(viewName);
                currentView = viewName;
                break;
        }
    }

    const showView = (viewName) => {
        const view = document.getElementById(viewName);
        if (view) {
            view.classList.remove('hidden');
        }

        showWindow();
    }

    const hideView = (viewName) => {
        const view = document.getElementById(viewName);
        if (view) {
            view.classList.add('hidden');
        }
    }

    const setCookieScript = (category) => {
        // console.log('[setCookieScript] (' + category + ')');
        const elements = document.querySelectorAll('[data-cookiecategory="' + category + '"]');

        elements.forEach(el=>{
            var newScript = document.createElement('script');
            // check for src
            if (el.src) {
                newScript.src = el.src;
            } else {
                // check for inline code
                var inlineScript = document.createTextNode(el.innerHTML);
                newScript.appendChild(inlineScript);
            }
            document.body.appendChild(newScript);
            el.remove();
        })
    }

    const setAllCookieScripts = () => {
        if (getCookie(ANALYTICAL_COOKIES_ALLOWED)) {
            setCookieScript(ANALYTICS);
        }

        if (getCookie(MARKETING_COOKIES_ALLOWED)) {
            setCookieScript(MARKETING);
        }
    }

    return {
        init: init
    }
}